import React from 'react';
import { graphql } from 'gatsby'

import Layout from '../../components/Layout';
import useMatchedQuery from '../../hooks/useMatchedQuery';

import ContentfulComponentSeo from '../../ContentfulComponents/ContentfulComponentSeo';

import ContentfulComponentHero from '../../ContentfulComponents/ContentfulComponentHero';

import ContentfulComponentStepsIndication from '../../ContentfulComponents/ContentfulComponentStepsIndication';


export const query = graphql`
query {
 

    contentfulComponentSeo0: contentfulComponentSeo (node_locale: {eq: "es-AR"}, contentful_id: {eq: "6Nor0M7bDTUDKnVZEjOw13"}) {
      
  title
  type
  description {
    description
  }
  image {
    id
    title
    gatsbyImageData(
      layout: FULL_WIDTH,
      placeholder: BLURRED,
    )
  }

      internal {
        type
      }    
    }
  





    contentfulComponentHero1: contentfulComponentHero (node_locale: {eq: "es-AR"}, contentful_id: {eq: "4A2RzxmTN3p1XJM8kVcfnX"}) {
      
id
title
contentful_id
node_locale
codeHero
gridModel
gridPosition
portraitGridModel
portraitGridPosition
textPosition
textColor
image {
  title
  gatsbyImageData(
    layout: FULL_WIDTH,
    placeholder: BLURRED,
  )
}
portraitImage {
  title
  gatsbyImageData(
    layout: FULL_WIDTH,
    placeholder: BLURRED,
  )
}
overlayText {
  raw
}

      internal {
        type
      }    
    }
  





    contentfulComponentStepsIndication2: contentfulComponentStepsIndication (node_locale: {eq: "es-AR"}, contentful_id: {eq: "7IC4HEo1gU9ijdOW8EsmAf"}) {
      
id
contentful_id
node_locale
title
direction
reveal {
  type
  props
}
indicationIcon {
  gatsbyImageData(
    layout: CONSTRAINED,
    placeholder: BLURRED,
    width: 500,
  )    
}
textImage {
  gatsbyImageData(
    layout: CONSTRAINED,
    placeholder: BLURRED,
    width: 500,
  )    
}
text {
  text
}

      internal {
        type
      }    
    }
  





    contentfulComponentStepsIndication3: contentfulComponentStepsIndication (node_locale: {eq: "es-AR"}, contentful_id: {eq: "3wvr6DraiGWOlyYuLd3sbQ"}) {
      
id
contentful_id
node_locale
title
direction
reveal {
  type
  props
}
indicationIcon {
  gatsbyImageData(
    layout: CONSTRAINED,
    placeholder: BLURRED,
    width: 500,
  )    
}
textImage {
  gatsbyImageData(
    layout: CONSTRAINED,
    placeholder: BLURRED,
    width: 500,
  )    
}
text {
  text
}

      internal {
        type
      }    
    }
  





    contentfulComponentStepsIndication4: contentfulComponentStepsIndication (node_locale: {eq: "es-AR"}, contentful_id: {eq: "3x3iZ8xJfIMqTMtJcbvm1W"}) {
      
id
contentful_id
node_locale
title
direction
reveal {
  type
  props
}
indicationIcon {
  gatsbyImageData(
    layout: CONSTRAINED,
    placeholder: BLURRED,
    width: 500,
  )    
}
textImage {
  gatsbyImageData(
    layout: CONSTRAINED,
    placeholder: BLURRED,
    width: 500,
  )    
}
text {
  text
}

      internal {
        type
      }    
    }
  





    contentfulComponentStepsIndication5: contentfulComponentStepsIndication (node_locale: {eq: "es-AR"}, contentful_id: {eq: "2H6oTW2kmSulspvZpUqYaq"}) {
      
id
contentful_id
node_locale
title
direction
reveal {
  type
  props
}
indicationIcon {
  gatsbyImageData(
    layout: CONSTRAINED,
    placeholder: BLURRED,
    width: 500,
  )    
}
textImage {
  gatsbyImageData(
    layout: CONSTRAINED,
    placeholder: BLURRED,
    width: 500,
  )    
}
text {
  text
}

      internal {
        type
      }    
    }
  





    contentfulComponentStepsIndication6: contentfulComponentStepsIndication (node_locale: {eq: "es-AR"}, contentful_id: {eq: "2jNG6VtKsFpT1yZyRgF3cQ"}) {
      
id
contentful_id
node_locale
title
direction
reveal {
  type
  props
}
indicationIcon {
  gatsbyImageData(
    layout: CONSTRAINED,
    placeholder: BLURRED,
    width: 500,
  )    
}
textImage {
  gatsbyImageData(
    layout: CONSTRAINED,
    placeholder: BLURRED,
    width: 500,
  )    
}
text {
  text
}

      internal {
        type
      }    
    }
  



 
 
 site {
  siteMetadata {
    siteUrl
  }
}}

`

const Page = (props) => {
  const seo = props.pageContext.seo;
  const matchedQuery = useMatchedQuery()
  const { contentIds, ...context } = props.pageContext
  const { data } = props;
  return (
    <Layout {...context} matchedQuery={matchedQuery} useHeader={true} useContainer={true} >
      
      <ContentfulComponentSeo {...props} matchedQuery={matchedQuery} useHeader={true} useContainer={true} componentId="contentfulComponentSeo0" pathName="/shop/como-funciona" />
      
      <ContentfulComponentHero {...props} matchedQuery={matchedQuery} useHeader={true} useContainer={true} componentId="contentfulComponentHero1" pathName="/shop/como-funciona" />
      
      <ContentfulComponentStepsIndication {...props} matchedQuery={matchedQuery} useHeader={true} useContainer={true} componentId="contentfulComponentStepsIndication2" pathName="/shop/como-funciona" />
      
      <ContentfulComponentStepsIndication {...props} matchedQuery={matchedQuery} useHeader={true} useContainer={true} componentId="contentfulComponentStepsIndication3" pathName="/shop/como-funciona" />
      
      <ContentfulComponentStepsIndication {...props} matchedQuery={matchedQuery} useHeader={true} useContainer={true} componentId="contentfulComponentStepsIndication4" pathName="/shop/como-funciona" />
      
      <ContentfulComponentStepsIndication {...props} matchedQuery={matchedQuery} useHeader={true} useContainer={true} componentId="contentfulComponentStepsIndication5" pathName="/shop/como-funciona" />
      
      <ContentfulComponentStepsIndication {...props} matchedQuery={matchedQuery} useHeader={true} useContainer={true} componentId="contentfulComponentStepsIndication6" pathName="/shop/como-funciona" />
      
    </Layout>
  )
}

export default Page;
